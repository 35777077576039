<template>
  <div class="sub-container flex-sb">
    <div class="content col">
      <section class="section">
        <div class="columnn-tab">
          <a href="#" class="columnn-tab__btn is-active">병원 상세정보</a>
          <a href="#" class="columnn-tab__btn">의료진 정보</a>
          <a href="#" class="columnn-tab__btn">건강 칼럼</a>
        </div>
        <table class="tbl-view app-detail-info input">
          <colgroup>
            <col class="head" />
            <col class="data" />
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th>병원 ID</th>
            <td class="inp-data">
              <input type="text" class="inp-txt-common full"  v-model="hospital.member.email">
            </td>
            <th>비밀번호</th>
            <td class="inp-data">
              <input type="password" class="inp-txt-common full"   v-model="hospital.member.password">
            </td>
          </tr>
          <tr>
            <th>병원명</th>
            <td class="inp-data">
              <input type="text" class="inp-txt-common full"   v-model="hospital.hospitalName">
            </td>
            <th>대표자</th>
            <td class="inp-data">
              <input type="text" class="inp-txt-common full"   v-model="hospital.ceoName">
            </td>
          </tr>
          <tr>
            <th>전화번호</th>
            <td class="inp-data">
              <input type="text" class="inp-txt-common full"   v-model="hospital.tel">
            </td>
            <th>이메일</th>
            <td class="inp-data">
              <input type="text" class="inp-txt-common full"   v-model="hospital.email">
            </td>
          </tr>
          <tr>
            <th>문의채널</th>
            <td class="inp-data" colspan="3">
              <input type="text" class="inp-txt-common full"  placeholder="카카오챗, 네이버톡등 상담톡 URL이나 전화번호, 이메일을 입력해주세요." v-model="hospital.contactChannel">
            </td>
          </tr>
          <tr>
            <th>주소</th>
            <td class="inp-data" colspan="3">
              <div class="addr flex-row">
                <input type="text" class="inp-txt-common post-num"  v-model="hospital.postcode">
                <button class="btn-outline btn-post"><span class="txt" @click="addressPopup = true">우편번호</span></button>
                <input type="text" class="inp-txt-common full" v-model="hospital.address1">
                <input type="text" class="inp-txt-common full" v-model="hospital.address2">
              </div>
            </td>
          </tr>
          <tr>
            <th>운영시간</th>
            <td class="inp-data" colspan="3">
              <div class="flex-row">
                <input type="text" class="inp-txt-common ins-cost" v-model="hospital.workTime1">
                <span class="bar"></span>
                <input type="text" class="inp-txt-common ins-cost" v-model="hospital.workTime2">
                <span class="bar"></span>
                <input type="text" class="inp-txt-common ins-cost" v-model="hospital.workTime3">
                <span class="bar"></span>
                <input type="text" class="inp-txt-common ins-cost" v-model="hospital.workTime4">
              </div>
            </td>
          </tr>
          <tr>
            <th>진료과목</th>
            <td colspan="3">
              <div class="select-wrap major">
                <select v-model="hospital.treatmentSubject" @change="changeTreatment">
                  <option :value="treatment.optionName"  v-for="treatment in treatmentData" v-bind:key="treatment.optionId">{{treatment.optionName}}</option>

                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th>의료비지원내용</th>
            <td colspan="3">
              <div class="t-radio-group">
                <div class="custom-checkbox" v-for="domesticMedicalCare in domesticMedicalCareList" v-bind:key="domesticMedicalCare.optionId">
                  <input
                          type="checkbox"
                          :id="'checkbox' + domesticMedicalCare.optionId"
                          :value="domesticMedicalCare.optionName"
                          v-model="domesticMedicalCareValue"
                  />
                  <label :for="'checkbox' + domesticMedicalCare.optionId" >{{domesticMedicalCare.optionName}}</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>재외동포지원내용</th>
            <td colspan="3">
              <div class="t-radio-group">
                <div class="custom-checkbox" v-for="overseaMedicalCare in overseaMedicalCareList" v-bind:key="overseaMedicalCare.optionId">
                  <input
                          type="checkbox"
                          :id="'checkbox' + overseaMedicalCare.optionId"
                          :value="overseaMedicalCare.optionName"
                          v-model="overseaMedicalCareValue"
                  />
                  <label :for="'checkbox' + overseaMedicalCare.optionId" >{{overseaMedicalCare.optionName}}</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>첨부서류</th>
            <td colspan="3">
              <div class="attach-file">
                <div class="attach-file-sub" v-for="document,index in hospital.documentList" v-bind:key="document.hospitalDocumentId">
                  <button  class="btn-down-file" ><span class="txt">{{document.documentName}}</span></button>
                  <button @click="removeDocument(index)" class=" ico-close" >&nbsp;</button>
                </div>

                <file-upload-single title="" button-title="서류첨부" v-on:uploaded="uploadDocument" input-id="upload-document" />

                <div class="attach-file-sub">
                  <button class="btn-down-all"><span class="txt">전체 다운로드</span></button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>보아스협력일자</th>
            <td>
              <div class="ts-date-picker-s" >
                <input type="date" class="ts-date-picker__inp-s inp-txt-common alone" v-model="hospital.cooperationDate">
              </div>
            </td>
            <th>등록일자</th>
            <td>
              <div class="ts-date-picker-s" >
                <input type="date" class="ts-date-picker__inp-s inp-txt-common alone" v-model="hospital.regDate">
              </div>
            </td>
          </tr>
          <tr>
            <th>병원로고</th>
            <td>
              <div
                      ref="hospital-logo"
                      id="hospital-logo"
                      style="width: 120px;height: 120px;background: #F0F0F0;"
                      @click="clickLogo"
              ></div>
            </td>
            <th>병원이미지</th>
            <td>
              <div
                      ref="hospital-image"
                      id="hospital-image"
                      style="width: 180px;height: 120px;background: #F0F0F0;"
                      @click="clickImage"
              ></div>
              <file-upload-single title="" button-title="로고 업로드" ref="upload-logo" v-on:uploaded="uploadLogo" :hidden="true" input-id="upload-logo" />

              <file-upload-single title="" button-title="이미지 업로드" ref="upload-image" v-on:uploaded="uploadImage" :hidden="true" input-id="upload-image" />

            </td>
          </tr>
          </tbody>
        </table>
        <div class="table-detail-foot flex-se" >
          <button class="btn-confirm tf-inp-btn" @click="add">
            <span class="txt ico apply-w" >병원정보 등록하기</span>
          </button>
        </div>
      </section>
    </div>
    <div class="content col">

    </div>
    <div class="address-popup" v-if="addressPopup">
      <DaumPostcode :on-complete="handleAddress" />
    </div>
  </div>
</template>
<style>


</style>
<script>
  import DaumPostcode from 'vuejs-daum-postcode'
  import FileUploadSingle from '../components/FileUploadSingle'

  export default {
    name: "HospitalAdd",
    components: {DaumPostcode,FileUploadSingle},

    data: () => ({
      hospital: {
        domesticMedicalCareList : [],
        overseaMedicalCareList: [],
        documentList:[],
        member: {}
      },
      status: null,
      statusContents: "",
      memoContents: "",
      memo: "",
      treatmentData: [],
      treatmentHash: {},
      domesticMedicalCareList: [],
      domesticMedicalCareValue: [],
      overseaMedicalCareList: [],
      overseaMedicalCareValue: [],
      hostpitalDocumentList:[],
      addressPopup: false,
    }),
    mounted() {
      console.log(this.$route.path);
      console.log(this.$route.params.hospitalId);
    },
    async beforeMount() {
      // this.getHospital();
      await this.listTreatment();
      console.log("####################################################");
      console.log(this.treatmentData);
      console.log("####################################################");
    },

    methods: {
      async getHospital() {
        const getHospitalPromise = await this.$axios.get(
                "hospital/" + this.$route.params.hospitalId
        );
        const [response] = await Promise.all([getHospitalPromise]);
        this.hospital = response.data.data;
        this.setTreatment();

        for(var i=0;i<this.hospital.domesticMedicalCareList.length;i++ ) {
          this.domesticMedicalCareValue.push(this.hospital.domesticMedicalCareList[i].careName);
        }
        for(i=0;i<this.hospital.overseaMedicalCareList.length;i++ ) {
          this.overseaMedicalCareValue.push(this.hospital.overseaMedicalCareList[i].careName);
        }

        if (this.hospital.worktime != undefined) {
          this.hospital.worktime = this.hospital.worktime.replace('\n','<span class="bar"></span>');
        }

        this.uploadLogo({url:this.hospital.logoImage});
        this.uploadImage({url:this.hospital.coverImage});

      },
      handleAddress(data) {
        console.log(data);
        this.hospital.address1 = data.roadAddress;
        this.hospital.area = data.sigungu;
        this.hospital.postcode = data.zonecode;

        this.addressPopup = false;
      },
      changeTreatment() {
        console.log(this.hospital.treatmentSubject);
        var optionId = this.treatmentHash[this.hospital.treatmentSubject];
        this.domesticMedicalCareValue = [];
        this.overseaMedicalCareList = [];
        this.hospital.domesticMedicalCareList = [];
        this.hospital.overseaMedicalCareList = [];
        this.setCareList('medical_care','domestic',optionId);
        this.setCareList('medical_care','oversea',optionId);
      },
      setTreatment() {
        console.log(this.hospital.treatmentSubject);
        var optionId = this.treatmentHash[this.hospital.treatmentSubject];
        this.domesticMedicalCareValue = [];
        this.overseaMedicalCareList = [];

        this.setCareList('medical_care','domestic',optionId);
        this.setCareList('medical_care','oversea',optionId);
      },
      async setCareList(optionType,supportType,parentId) {
        var param = {
          optionType : optionType,
          supportType : supportType,
          parentOptionId : parentId,
        }
        console.log(param);
        const getPromise = await this.$axios.get("depth2-options",{params: param});
        const [response] = await Promise.all([getPromise]);

        if (response.data.success) {
          if (supportType == 'domestic') {
            this.domesticMedicalCareList = response.data.data;
          } else if ( supportType == 'oversea') {
            this.overseaMedicalCareList = response.data.data;
          }
        }
      },
      removeDocument(index) {
        console.log(index);
        this.hospital.documentList.splice(index, 1);

      },
      clickLogo(){
        console.log("clickLogo");
        this.$refs["upload-logo"].openFile();
      },
      clickImage() {
        this.$refs["upload-image"].openFile();
      },
      uploadImage(param) {
        this.hospital.coverImage = param.url;
        document.getElementById("hospital-image").style.backgroundRepeat = "no-repeat";
        //이미지 등록
        document.getElementById("hospital-image").style.backgroundImage = "url('" + param.url + "')";
        //이미지 사이즈
        document.getElementById("hospital-image").style.backgroundSize = "180px";



      },
      uploadLogo(param) {
        this.hospital.logoImage = param.url;
        document.getElementById("hospital-logo").style.backgroundRepeat = "no-repeat";
        //이미지 등록
        document.getElementById("hospital-logo").style.backgroundImage = "url('" + param.url + "')";
        //이미지 사이즈
        document.getElementById("hospital-logo").style.backgroundSize = "180px";
      },
      uploadDocument(param) {
        this.hospital.documentList.push({
          hospitalId: this.hospital.hospitalId,
          documentName: param.fileName,
          documentUrl: param.url
        })
      },
      convertDateFromat(value) {
        if (value != undefined) {
          var resultDate = new Date(value);
          const offset = resultDate.getTimezoneOffset();
          resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
          return (
                  resultDate.toISOString().split("T")[0] +
                  " " +
                  resultDate
                          .toISOString()
                          .split("T")[1]
                          .substring(0, 8)
          );
        } else {
          return "";
        }
      },
      async add() {
        console.log(this.hospital);
        console.log(this.domesticMedicalCareValue);
        console.log(this.overseaMedicalCareValue);

        this.hospital.domesticMedicalCareList = [];
        this.hospital.overseaMedicalCareList = [];

        for(var i=0;i<this.domesticMedicalCareValue.length;i++) {
          this.hospital.domesticMedicalCareList.push({
            supportType: 'domestic',
            careName: this.domesticMedicalCareValue[i],
            hospitalId: this.hospital.hospitalId,
          })

        }

        for(i=0;i<this.overseaMedicalCareValue.length;i++) {
          this.hospital.overseaMedicalCareList.push({
            supportType: 'oversea',
            careName: this.overseaMedicalCareValue[i],
            hospitalId: this.hospital.hospitalId,
          })

        }

        for(i=0;i<this.hospital.documentList.length;i++) {
          this.hospital.documentList[i].hospitalDocumentId = null ;
        }

        this.$axios
                .post("hospital", this.hospital)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    //this.$router.push({ path: '/hospital/' + this.hospital.hospitalId});

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      },

      treatHashSet() {
        for(var i=0;i<this.treatmentData.length;i++) {
          this.treatmentHash[this.treatmentData[i].optionName] = this.treatmentData[i].optionId;
        }
        console.log(this.treatmentHash);
      },

      async listTreatment() {
        const getTreatmentListPromise = await this.$axios.get("depth1-options",{params: { optionType : 'treatment_subject'}});
        const [response] = await Promise.all([getTreatmentListPromise]);

        if (response.data.success) {
          this.treatmentData = response.data.data;
          this.treatHashSet();

        }
        // this.treatmentArray =
      }
    }
  };
</script>
